import {
    MapConfig,
    SET_MAP_CONFIG,
    SET_MAP_KEYWORD,
    SET_MAP_LOCATIONS,
    SetMapConfig,
    Location,
    SetMapKeyword,
    SetMapLocations, SetSearchResult, SET_SEARCH_RESULT
} from "../types/MapConfigType";
import {FetchHousingResponse} from "../types/HousingTypes";


export const setMapConfig = (mapConfig : MapConfig): SetMapConfig => ({
    type: SET_MAP_CONFIG,
    mapConfig
});


export const setSearchResult = (data : FetchHousingResponse): SetSearchResult => ({
    type: SET_SEARCH_RESULT,
    data
});


export const setMapKeyword = (keyword : string): SetMapKeyword => ({
    type: SET_MAP_KEYWORD,
    keyword
});


export const setMapLocations = (locations : Location[]): SetMapLocations => ({
    type: SET_MAP_LOCATIONS,
    locations
});
