
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import React, {useEffect, useState} from "react";
import Head from 'next/head';
import type {AppProps /*, AppContext */} from 'next/app'
import theme from "../src/theme";
import {ThemeProvider} from "@material-ui/styles";
import {Provider} from 'react-redux';
import {Layout} from "../src/common_components";
import '../src/global.css'
import 'swiper/swiper-bundle.css';
import store from "../src/store";
import {SnackbarProvider} from "notistack";
import {AuthLoginContext} from "../src/common_components/UserAuthProvider";
import CssBaseline from '@material-ui/core/CssBaseline';
import Script from 'next/script'
import Login from "../src/page_components/login_dialog";
import {useRouter} from "next/router";
import * as ga from 'ga/actions'

function MyApp({Component, pageProps}: AppProps) {
    useEffect(() => {
        if (typeof window !== 'undefined') {
                const loader = document.getElementById('globalLoader');
            if (loader)
                loader.style.display = 'none';
        }
    }, []);
    React.useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles);
        }
    }, []);

    const [handleLogin, setHandleLogin] = useState<{
        afterLogin: (key: string) => void,
        key: string
    } | undefined>();

    const router = useRouter()
    useEffect(() => {
        const handleRouteChange = (url) => {
            ga.pageview(url)
        }
        router.events.on('routeChangeComplete', handleRouteChange)
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange)
        }
    }, [router.events])

    return <>
        {/* Global Site Tag (gtag.js) - Google Analytics */}
        <Head>
            <title>Lespioles</title>
            <link rel="preconnect" href="https://fonts.gstatic.com"/>
            <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap"
                  rel="stylesheet"/>
            <meta name="author" content="Lespioles | contact.lespioles.com"/>

            <meta name="theme-color" content="#00A8FF"/>
            <meta name="image" content="%PUBLIC_URL%/logo.png"/>
            <meta name="description" content="Trouver des logements dans toutes les villes"
            />
            <meta property="og:image" content="https://app.lespioles.com/banner.jpg"/>

            <meta property="og:title" content="Lespioles"/>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta property="og:description" content="Trouver des logements dans toutes les villes"/>


            <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo.png"/>
        </Head>
        {(typeof window != 'undefined' &&  !(window as any).mixpanel &&
            <Script
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
            __html: `
              (function(f,b){if(!b.__SV){var e,g,i,h;window.mixpanel=b;b._i=[];b.init=function(e,f,c){function g(a,d){var b=d.split(".");2==b.length&&(a=a[b[0]],d=b[1]);a[d]=function(){a.push([d].concat(Array.prototype.slice.call(arguments,0)))}}var a=b;"undefined"!==typeof c?a=b[c]=[]:c="mixpanel";a.people=a.people||[];a.toString=function(a){var d="mixpanel";"mixpanel"!==c&&(d+="."+c);a||(d+=" (stub)");return d};a.people.toString=function(){return a.toString(1)+".people (stub)"};i="disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking start_batch_senders people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(" ");
            for(h=0;h<i.length;h++)g(a,i[h]);var j="set set_once union unset remove delete".split(" ");a.get_group=function(){function b(c){d[c]=function(){call2_args=arguments;call2=[c].concat(Array.prototype.slice.call(call2_args,0));a.push([e,call2])}}for(var d={},e=["get_group"].concat(Array.prototype.slice.call(arguments,0)),c=0;c<j.length;c++)b(j[c]);return d};b._i.push([e,f,c])};b.__SV=1.2;e=f.createElement("script");e.type="text/javascript";e.async=!0;e.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?
            MIXPANEL_CUSTOM_LIB_URL:"file:"===f.location.protocol&&"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\\/\\//)?"https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";g=f.getElementsByTagName("script")[0];g.parentNode.insertBefore(e,g)}})(document,window.mixpanel||[]);

            mixpanel.init('90cbd6f000b27237ae00bafa9c7f6de4', {debug: false});
            // mixpanel.track('Sign up');
  `,
        }}
            />)}
        <SnackbarProvider
            maxSnack={5}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <AuthLoginContext.Provider value={{
                        showLoginInterface: (afterLogin, key) => {
                            setHandleLogin({
                                afterLogin,
                                key
                            })
                        }
                    }}>
                        <Login
                            login_reason={handleLogin?.key}
                            open={!!handleLogin}
                            onLoginSuccess={() => {
                                if (handleLogin)
                                    handleLogin.afterLogin(handleLogin.key);
                            }}
                            setOpen={() => setHandleLogin(undefined)}/>
                        <Layout>
                            <Component {...pageProps} />
                        </Layout>
                    </AuthLoginContext.Provider>
                </ThemeProvider>
            </Provider>
        </SnackbarProvider>

    </>
}

const __Page_Next_Translate__ = MyApp


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  