import React, {Component} from 'react';
import {GoogleApiWrapper, Map, mapEventHandler} from 'google-maps-react';
import config from 'config';
import {Box} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import {Location} from 'assets'

const mapStyles = {
    width: '100%',
    height: '100%'
};

interface GoogleMapProps {
    google: Readonly<{}>;
    onMapReady?: mapEventHandler;
    onTilesloaded?: mapEventHandler;
    onClick?: mapEventHandler;
    children?: React.ReactNode,
    visible: boolean
    initialCenter?: {
        lat: number
        lng: number
    }
    zoom?: number
}

export class MapContainer extends Component<GoogleMapProps> {
    render() {
        return (                <Map
            google={this.props.google}
            // visible={this.props.visible}
            style={mapStyles}
            // @ts-ignore
            zoom={this.props.zoom || 6}
            onClick={this.props.onClick}
            onTilesloaded={this.props.onTilesloaded}
            onReady={this.props.onMapReady}
            initialCenter={this.props.initialCenter || {
                lat: 3.861651,
                lng: 11.520706
            }}
        >{this.props.children}</Map>
        );
    }
}

export const LoadingContainer = (props) => (
    <Box position={'absolute'}
         zIndex={10}
         top={0}
         bgcolor={'#fff'}
         bottom={0}
         display={'flex'}
         alignItems={'center'}
         justifyContent={'center'}
         height={'100%'} width={'100%'}>
        <Location/>
        <Skeleton
            component={'div'}
            style={{borderRadius: 0, transform: 'none', position: 'absolute'}} width={'100%'} height={'100%'}/>
    </Box>
)

export default GoogleApiWrapper({
    apiKey: config.map_key || '', language: 'fr',
})(MapContainer);
