import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Button, Dialog, DialogContent, Grid, Typography, useMediaQuery, useTheme} from '@material-ui/core';
// @ts-ignore
import {OldSocialLogin as SocialLogin} from 'react-social-login';
import {X} from 'react-feather'
import {AuthService} from 'service/api';
import {useDispatch, useSelector} from 'react-redux'
// import 'react-phone-input-mui/dist/style.css'
import {EmailSign, Form} from "./Component";
import {loginSuccess} from "store/actions";
import {useRouter} from "next/router";
import useTranslation from "next-translate/useTranslation";
import {FacebookIcon, GoogleIcon} from 'assets';
import {ButtonPrimary} from "common_components";
import {useSnackbar} from "notistack";
import {User} from "../../store/types/AccountTypes";
import {ApplicationState} from "../../store/types";
import * as ga from "../../ga/actions";

const useStyles = makeStyles(theme => ({
    fab: {
        width: '100%',
        maxWidth: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        backgroundColor: '#fff',
        '& div': {
            marginLeft: 8,
            fontWeight: 600
        }
    },
    icon: {
        padding: '0 8px',
        [theme.breakpoints.down('xs')]: {
            padding: 0
        }
    },
    form: {
        width: 350,
        maxWidth: 'calc(100% - 32px)',
        margin: '0 auto'
    },
    content: {
        padding: "0!important",
        [theme.breakpoints.down('sm')]: {
            padding: '8px 0'
        }
    },
    dividerContainer: {
        position: 'relative',
        height: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    divider: {
        height: '1px',
        width: '100%',
        backgroundColor: theme.palette.divider
    },
    dividerLabel: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -60%)',
        padding: '0 4px',
        backgroundColor: '#fff'
    },
}));

interface LoginProps {
    open: boolean,
    login_reason?: string,
    setOpen: (open: boolean) => void
    onLoginSuccess?: () => void
}

export default function Login(props: LoginProps) {
    const classes = useStyles();
    const {open, setOpen} = props;
    const {i18n: {t, locale}} = useSelector((state: ApplicationState) => state);
    const [loading, setLoading] = React.useState<string | undefined>();
    const [provider, setProvider] = React.useState('facebook');
    // const [loadingSignIn, setLoadingsignIn] = React.useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const dispatch = useDispatch();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const router = useRouter();
    const [step, setStep] = useState(0);
    // const {t} = useTranslation();
    console.log('current_locale', locale);
    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {}
    });

    const handleSocialLogin = (user: any, err) => {
        if (!err) {
            setLoading(t('common:login_state_one'));
            setProvider(user._provider);
            console.log(user);
            AuthService.login(user._provider, user._provider === 'facebook' ? user._token.accessToken : user._token.idToken)
                .then(async response => {
                    if (response.status === 200 || response.status === 201) {
                        await response.json().then(data => {
                            if (data.status) {
                                dispatch(loginSuccess(data.data, data.token));
                                enqueueSnackbar(t('common:welcome_back', {surname: data.data.surname}), {
                                    variant: 'success'
                                });
                                ga.event('Web Login', {
                                    method: user._provider,
                                })

                                const u: User = data.data;
                                ga.identify(u.id, u.name, u.surname);

                                handleLoginSuccess();
                            } else {
                                //
                                // setFormState({
                                //     ...formState,
                                //     values: {
                                //         name: data?.data?.name,
                                //         surname: data?.data?.surname,
                                //         token: user._provider === 'facebook' ? user._token.accessToken : user._token.idToken
                                //     }
                                // })
                                // setStep(1)
                                handleSocialSignIn(user._provider, {
                                    name: data?.data?.name,
                                    surname: data?.data?.surname,
                                    token: user._provider === 'facebook' ? user._token.accessToken : user._token.idToken
                                });
                            }
                        });
                    } else {
                        const json = await response.json();
                        enqueueSnackbar(json.message, {
                            variant: 'warning'
                        })
                    }
                })
                .catch(() => {
                    enqueueSnackbar(t('common:check_internet_connexion'), {
                        variant: 'error'
                    })
                })
                .finally(() => setLoading(undefined));
        }
    };

    const handleSocialSignIn = (provider, values) => {
        setLoading(t('common:login_state_two'));
        setProvider(provider);
        AuthService.signIn(provider, values)
            .then(async response => {
                if (response.status === 200 || response.status === 201) {
                    await response.json().then(data => {
                        if (data.status) {
                            dispatch(loginSuccess(data.data, data.token));
                            enqueueSnackbar(t('common:welcome_back', {surname: data.data.surname}), {
                                variant: 'success'
                            });
                            ga.event('Web Sign-in', {
                                method: provider,
                            })
                            const user: User = data.data;
                            ga.identify(user.id, user.name, user.surname);
                            handleLoginSuccess();
                        } else {
                            enqueueSnackbar(data.data, {
                                variant: 'warning'
                            })
                        }
                    });
                } else {
                    const json = await response.json();
                    enqueueSnackbar(json.message, {
                        variant: 'warning'
                    })
                }
            })
            .catch(() => {
                enqueueSnackbar(t('common:check_internet_connexion'), {
                    variant: 'error'
                })
            })
            .finally(() => setLoading(undefined));
    };

    const handleLoginSuccess = () => {
        setStep(0);
        if (router.pathname.indexOf('auth') !== -1) {
            const after = new URL(window.location.href).searchParams.get('after');
            if (after)
                router.push(after);
            else
                router.push("/");
        } else {
            if (!!props.onLoginSuccess)
                props.onLoginSuccess();
            setOpen(false);
        }
    }
    const handleClose = () => {
        setStep(0);
        if (router.pathname.indexOf('auth') !== -1)
            router.push("/");
        else
            setOpen(false);
    };
    return (
        !t ? <></> :
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
            >
                <DialogContent className={classes.content}
                               style={{height: '100%', display: 'flex', alignItems: 'center'}}>
                    <Grid item container component={'div'} style={{height: '100%'}} alignItems={'stretch'}>
                        <Grid item md={6} xs={12}
                              component={'div'}
                              style={{padding: '16px 24px'}}
                              container
                              alignItems={'center'}
                              direction={'column'}
                              justify={'space-between'}>
                            <div>
                                <Typography align={'center'} gutterBottom color={'secondary'} component={'h4'}
                                            variant={'h2'}>
                                    {t('common:login_or_register')}
                                </Typography>
                                {!!props?.onLoginSuccess &&
                                <Typography align={'center'} color={'textSecondary'} component={'p'} variant={'body1'}>
                                    {t((props?.login_reason || '').includes('favorite') ? 'common:connect_before_action_favorite' : 'common:connect_before_action')}
                                </Typography>}
                            </div>
                            <Box component={'div'} className={classes.form}>
                                {/*{step !== 1 && <Box component={'div'} width={'100%'}>*/}
                                {/*    <EmailSign/>*/}
                                {/*</Box>}*/}
                                <div>
                                    {
                                        // step === 1 ?
                                        //     <Form loading={loadingSignIn}
                                        //           setFormState={setFormState}
                                        //           formState={formState}
                                        //           handleSend={handleSocialSignIn}
                                        //
                                        //     /> :
                                        <Box component={'div'}>
                                            <Box mb={3} component={'div'}>
                                                <SocialLogin
                                                    provider='facebook'
                                                    appId='463000774643314'
                                                    callback={handleSocialLogin}
                                                >
                                                    <ButtonPrimary
                                                        disabled={!!loading}
                                                        style={{
                                                            opacity: !!loading && provider === 'google' ? 0 : 1
                                                        }}
                                                        loading={!!loading && provider === 'facebook'}
                                                        startIcon={<div className={classes.icon}>
                                                            <FacebookIcon/>
                                                        </div>}
                                                        fullWidth
                                                        size={'large'}
                                                        variant={'outlined'} color={'secondary'}>
                                                        <Box component={'div'} flexGrow={1} textAlign={'left'}>
                                                            {t('common:continue_with_facebook')}
                                                        </Box>
                                                    </ButtonPrimary>
                                                </SocialLogin>
                                            </Box>
                                            <Box style={{
                                            opacity: !!loading ? 0 : 1
                                            }} mb={3} className={classes.dividerContainer} component={'div'}>
                                                <div className={classes.divider}/>
                                                <Typography className={classes.dividerLabel} variant={'body1'}
                                                            component={'div'}>
                                                    {t('common:or')}
                                                </Typography>
                                            </Box>
                                            <Box mb={3} component={'div'} style={{
                                                opacity: !!loading && provider === 'facebook' ? 0 : 1
                                            }}>
                                                <SocialLogin
                                                    provider='google'
                                                    appId='559092813290-go8rqj6s72nr5ipro2sq5ngosprrdic6.apps.googleusercontent.com'
                                                    callback={handleSocialLogin}
                                                >
                                                    <ButtonPrimary
                                                        disabled={!!loading}
                                                        loading={!!loading && provider === 'google'}
                                                        startIcon={<div className={classes.icon}>
                                                            <GoogleIcon/></div>}
                                                        fullWidth
                                                        size={'large'}
                                                        variant={'outlined'} color={'secondary'}>
                                                        <Box component={'div'} flexGrow={1} textAlign={'left'}>
                                                            {t('common:continue_with_google')}
                                                        </Box>
                                                    </ButtonPrimary>
                                                </SocialLogin>
                                            </Box>
                                            {typeof loading !== 'undefined' &&
                                            <Typography variant={'subtitle2'} color={'textSecondary'} align={'center'}>
                                                {loading}
                                            </Typography>}
                                        </Box>
                                    }
                                </div>
                            </Box>
                            <Box component={'div'} className={classes.form}>
                                <Button
                                    onClick={handleClose}
                                    fullWidth
                                    size={'large'}
                                    variant={'outlined'} color={'secondary'}>
                                    {t('common:continue_later')}
                                </Button>
                            </Box>
                        </Grid>
                        {
                            !matches &&
                            <Grid
                                item
                                md={6}
                                xs={12}
                                component={'div'}
                                container
                                alignItems={'center'}
                                justify={'center'}
                                style={{position: 'relative'}}>
                                <Box component={'div'}
                                     bgcolor={'#05153f'}
                                     top={0}
                                     bottom={0}
                                     width={'100%'}
                                     height={'100%'}
                                     position={'absolute'}>
                                </Box>
                                <img src="/logo_white.svg" style={{width: 100, position: 'relative', zIndex: 2}}
                                     alt=""/>
                            </Grid>
                        }
                    </Grid>
                </DialogContent>
            </Dialog>
    );
}
