import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Grid, LinearProgress, useMediaQuery, useTheme} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {setMapConfig} from "../../store/actions/MapConfigActions";
import {MapContainer} from "../index";
import {useRouter} from "next/router";
import clsx from "clsx";
import {ApplicationState} from "../../store/types";
import {GoogleAPI, InfoWindow, Marker} from "google-maps-react";
import {LoadingContainer} from "../MapContainer/MapContainer";
import useTranslation from "next-translate/useTranslation";
import {setTranslationFunction} from "../../store/actions/InternationalisationActions";
import {Housing} from "../../store/types/HousingTypes";
import InfoxBoxResult from "page_components/search/InfoxBoxResult/InfoxBoxResult";

const useStyles = makeStyles(theme => ({
    rootGrid: {
        minHeight: '100vh',
        '& .ps__rail-x': {
            display: 'none!important'
        },
    },
    childrenGrid: {
        position: 'relative',
        borderRight: `1px solid ${theme.palette.grey.A100}`,
        padding: '0 16px',
        [theme.breakpoints.down('sm')]: {
            borderRight: 'none',
            padding: '0 8px'
        },
    },
    mapGrid: {
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            // display: 'none'
            // overflow: 'hidden',
            opacity: 0,
        }
    },
    logoContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& img': {
            width: 64
        }
    }
}));

interface LayoutProps {
    children: React.ReactNode
}

export default function Layout(props: LayoutProps) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const router = useRouter();
    const house_page = /search\/.+\/.+/.test(router.pathname)
        || router.pathname.includes('/404')
        || router.pathname.includes('/policy')
        || router.pathname.includes('/500');
    const mapRef = useRef<any>();
    const googleRef = useRef<GoogleAPI>();
    const {t, lang} = useTranslation();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    useEffect(() => {
        dispatch(setTranslationFunction(t));
    }, [dispatch, t]);
    const {searchResult, mapConfig} = useSelector((state: ApplicationState) => state.mapConfig);
    const {user} = useSelector((state: ApplicationState) => state.account);
    const google = googleRef.current || mapConfig?.mapProps?.google;
    const [mapLoaded, setMapLoaded] = useState(false);
    const [titleLoad, setTitleLoad] = useState(false);
    const [housingDetail, setHousingDetail] = useState<Housing | undefined>();
    const {q, filter} = router.query as { q: string, filter?: string };

    const [placeLoading, setPlaceLoading] = useState(false);

    const ty = typeof window
    useEffect(() => {
        if ((searchResult?.housings || []).length > 0 && google) {
            const bounds = new google.maps.LatLngBounds();
            searchResult?.housings.forEach(housing => {
                bounds.extend({
                    lat: housing.false_latitude,
                    lng: housing.false_longitude
                });
            });
            if (typeof mapRef.current !== 'undefined') {
                mapRef.current.fitBounds(bounds);
                const zoom = mapRef.current.getZoom();
                if (zoom > 15)
                    mapRef.current.setZoom(15);
            }
        }
    }, [searchResult, titleLoad]);


    return (
        house_page ? <div>
                {props.children}
            </div> :
            <Grid container component={'div'} alignItems={'stretch'} className={classes.rootGrid}>
                <Grid item lg={6} md={7} xs={12} component={'div'}
                      className={clsx(classes.childrenGrid, 'layout-child')}>
                    {props.children}
                </Grid>
                <Grid item lg={6} md={5} sm={12} xs={12} component={'div'} className={classes.mapGrid}>
                    {placeLoading && <LinearProgress style={{
                        position: 'absolute',
                        zIndex: 10,
                        left: 0,
                        top: 0,
                        width: '100%'
                    }}/>}
                    {!mapLoaded && <LoadingContainer/>}
                    {
                        ['/auth', '/auth/register'].indexOf(router.pathname) === -1 ?
                            <MapContainer
                                visible={!matches}
                                onTilesloaded={(mapProps, map) => {
                                    mapRef.current = map;
                                    googleRef.current = mapProps?.google;
                                    dispatch(setMapConfig({
                                        map,
                                        mapProps
                                    }));
                                    setMapLoaded(true);
                                    setTimeout(() => {
                                        setTitleLoad(true);
                                    }, 1000)
                                }}>
                                {mapRef.current && googleRef.current &&
                                    //@ts-ignore
                                    <InfoWindow
                                        //@ts-ignore
                                        position={{
                                            lat: (parseFloat(housingDetail?.false_latitude) || 3) + .001,
                                            lng: housingDetail?.false_longitude || 11
                                        }}
                                        //@ts-ignore
                                        onClose={() => {
                                            setHousingDetail(undefined)
                                        }}
                                        zIndex={100}
                                        visible={!!housingDetail}
                                    >
                                        <Box bgcolor={'#fff'} width={300}>
                                            {housingDetail && <InfoxBoxResult
                                                user={user}
                                                data={housingDetail}
                                                lang={lang}
                                                t={t}
                                                equipments={searchResult?.global_data?.equipments || []}
                                                rooms={searchResult?.global_data?.rooms || []}
                                            />}
                                        </Box>
                                    </InfoWindow>}
                                {searchResult?.location?.geometry?.location && <Marker
                                    //@ts-ignore
                                    draggable
                                    //@ts-ignore
                                    title={`${q}`}
                                    //@ts-ignore
                                    onDragend={async (mapProps, map, clickEvent) => {
                                        const position = {
                                            lat: clickEvent.latLng.lat(),
                                            lng: clickEvent.latLng.lng(),
                                        }
                                        setPlaceLoading(true);
                                        await router.push(`/search?lat=${position.lat}&lng=${position.lng}${!!filter ? `&filter=${filter}` : ''}`);
                                        setPlaceLoading(false);
                                    }}
                                    //@ts-ignore
                                    position={{
                                        lat: searchResult?.location?.geometry?.location?.lat,
                                        lng: searchResult?.location?.geometry?.location?.lng
                                    }}
                                />}
                                {(searchResult?.housings || []).map((housing, k) => (
                                    <Marker
                                        key={housing?.id || k}
                                        onClick={() => {
                                            setHousingDetail(housing);
                                        }}
                                        //@ts-ignore
                                        draggable={false}
                                        //@ts-ignore
                                        icon={
                                            google && {
                                                url: '/ic_map.png',
                                                anchor: new google.maps.Point(16, 16),
                                                scaledSize: new google.maps.Size(32, 32)
                                            }
                                        }
                                        //@ts-ignore
                                        title={`${housing.cities.city}  ·  ${housing.cities.district}`}
                                        //@ts-ignore
                                        position={{
                                            lat: housing.false_latitude,
                                            lng: housing.false_longitude
                                        }}
                                    />
                                ))}
                            </MapContainer> :
                            <Box component={'div'} className={classes.logoContainer}>
                                <img src="/logo.svg" alt=""/>
                            </Box>
                    }
                </Grid>
            </Grid>
    );
}
