export default {
  root: {
    minHeight: 45,
    boxShadow: 'none!important',
    textDecoration: 'none',
    padding: '8px 24px !important',
    textTransform: 'inherit',
    borderRadius: '33px !important'
  },
  contained: {
    padding: '8px 24px !important',
    boxShadow: 'none!important',
    textDecoration: 'none',
    borderRadius: '33px !important'
  },
  outlined: {
    textDecoration: 'none',
    padding: '8px 32px',
    borderWidth: '2px!important',
    boxShadow: 'none!important',
    borderRadius: 33
  }
};
