import {combineReducers, Reducer} from 'redux';
import AccountReducer from './reducers/AccountReducer';
import MapConfigReducer from './reducers/MapConfigReducer';

import {ApplicationState} from './types';
import InternationalisationReducer from "./reducers/InternationalisationReducer";

const rootReducer: Reducer<ApplicationState> = combineReducers<ApplicationState>({
    account: AccountReducer,
    i18n: InternationalisationReducer,
    mapConfig: MapConfigReducer
});

export default rootReducer;
