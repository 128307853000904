import React from 'react'

export default function Facebook() {
    return <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
            <path
                d="M21.3691 9.78568C21.0299 9.59616 20.6014 9.71429 20.4105 10.0507C20.2189 10.3885 20.3377 10.8177 20.6755 11.0093C22.2596 11.9067 23.1786 13.0108 23.1786 14.1184C23.1786 16.0431 20.5328 17.862 16.6642 18.6454C16.2831 18.723 16.0373 19.0938 16.1142 19.4742C16.1924 19.8584 16.5683 20.1014 16.943 20.0242C21.5565 19.0897 24.5848 16.7716 24.5848 14.1184C24.5848 12.4821 23.4125 10.9433 21.3691 9.78568Z"
                fill="#262727"/>
            <path
                d="M9.06934 20.1707V22.5559C9.06934 22.8154 9.21216 23.0537 9.44082 23.176C9.66629 23.2969 9.94449 23.2862 10.1625 23.1409L14.3813 20.3284C14.577 20.198 14.6944 19.9782 14.6944 19.7434C14.6944 19.5086 14.577 19.2889 14.3813 19.1584L10.1625 16.3459C9.94623 16.2031 9.66812 16.188 9.44082 16.3109C9.21216 16.4331 9.06934 16.6713 9.06934 16.9309V18.7318C4.9583 18.0275 1.99121 16.1569 1.99121 14.1184C1.99121 13.0108 2.91015 11.9067 4.4942 11.0093C4.83202 10.8177 4.9508 10.3885 4.75923 10.0507C4.56835 9.71425 4.14057 9.59617 3.80068 9.78569C1.75726 10.9434 0.584961 12.4821 0.584961 14.1184C0.584961 16.9828 3.99905 19.3715 9.06934 20.1707Z"
                fill="#262727"/>
            <path
                d="M18.2097 3.48846L12.8992 0.833178C12.7014 0.734318 12.468 0.734318 12.2702 0.833178L6.95947 3.4886L12.5847 6.30096L18.2097 3.48846Z"
                fill="#262727"/>
            <path
                d="M18.9131 11.306V4.70947L13.2881 7.52197C13.2881 7.66635 13.2881 14.6976 13.2881 14.5532L18.5244 11.9351C18.7627 11.8155 18.9131 11.5725 18.9131 11.306Z"
                fill="#262727"/>
            <path
                d="M6.25684 11.3058C6.25684 11.5723 6.40721 11.8153 6.64548 11.9348L11.8818 14.553C11.8818 14.0594 11.8818 7.37727 11.8818 7.52174L6.25684 4.70947V11.3058Z"
                fill="#262727"/>
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="24" height="24" fill="white" transform="translate(0.584961 0.0090332)"/>
            </clipPath>
        </defs>
    </svg>
}