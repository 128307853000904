import React from 'react';
import {Box} from '@material-ui/core';
// import 'swiper/swiper-bundle.css';
import {Housing, HousingType} from "store/types/HousingTypes";
import {User} from "store/types";
import styles from './CardCityResult.module.css'
import clsx from "clsx";
import slugify from "react-slugify";


interface CardCityResultProps {
    data?: Housing,
    equipments: HousingType[],
    rooms: HousingType[],
    user?: User,
    t?: (key: string, options?: any) => string,
    lang: string
}

export default function InfoxBoxResult({data, rooms, user, lang, t}: CardCityResultProps) {
    // const classes = useStyles();
    // const {t, lang} = useTranslation();
    // const [loading, setLoading] = useState(false);
    // const theme = useTheme();
    const assets = (data?.assets || []).map(a => `https://files.lespioles.com/${a.url_mini}`);
    const more = assets.filter((i, k) => k > 2).length;
    const link = `/search/${slugify(data?.cities?.city || 'house')}/${slugify(data?.cities?.district || 'detail')}-${data.id}`;
    return (
        <div className={clsx(styles.root, {
            [styles.simple_root]: more <= 0
        })}>
            <a href={link} target={'_blank'}>
                <div className={clsx(styles.image_container, {
                    [styles.grid_tree_or_more]: assets.length >= 3,
                    [styles.grid_zero]: assets.length === 0,
                    [styles.grid_one]: assets.length === 1,
                    [styles.grid_two]: assets.length === 2,
                })}>
                    {
                        assets
                            .filter((i, k) => k <= 2)
                            .map(i => <Box className={styles.img} component={'div'} height={'100%'} width={'100%'}>
                                <img src={i}/>
                            </Box>)
                    }
                    {!!more && <div className={styles.more_image}>
                        +{more}
                    </div>}
                </div>
            </a>
            <h3 className={clsx('MuiTypography-root MuiTypography-h3 MuiTypography-gutterBottom', styles.primaryColor)}>
                {lang === 'fr' ? data.housing_types.title_fr : data.housing_types.title_en}
            </h3>
            <div className={clsx('MuiTypography-root MuiTypography-subtitle2', styles.secondaryColor)}>
                {t('common:card_city_result_city',
                    {
                        city_type: lang === 'fr' ? data.cities?.type_of_cities?.title_fr :
                            data.cities?.type_of_cities?.title_en
                    })}
                {/*Chez papa Ema*/}
            </div>
            <Box my={1}>
                <hr className={'MuiDivider-root'}/>
            </Box>
            <div className={clsx('MuiTypography-root MuiTypography-subtitle2', styles.secondaryColor)}>
                {
                    data.housing_rooms
                        .filter(room => rooms.map(r => r.id).includes(room.room_id))
                        .map(i => {
                            const room = rooms.find(r => r.id === i.room_id)
                            return `${i?.quantity} ${lang === 'fr' ? room.title_fr : room.title_en}`
                        }).join(' · ')
                }
            </div>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mt={4}>
                <div className={clsx( styles.primaryColor)}>
                    <span className={'MuiTypography-root MuiTypography-subtitle2'}>~{new Intl.NumberFormat(`${lang}-${lang.toUpperCase()}`, {
                        style: 'currency',
                        currency: 'XAF'
                    })
                        .format(+`${data.price}` + +`${data.price_benefit || '0'}`)}</span>{' '}
                    <span className={'MuiTypography-root MuiTypography-subtitle1'}>{t('common:house_detail_about_price')}</span>
                </div>
                <a href={link} target={'_blank'}>
                    <button
                        className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedSecondary MuiButton-containedSizeSmall MuiButton-sizeSmall"
                        type="button">
                        <span className="MuiButton-label">{t('common:more_detail')}</span>
                        <span className="MuiTouchRipple-root"/></button>
                </a>
            </Box>
        </div>
    );
}
