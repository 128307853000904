import * as Sentry from "@sentry/nextjs";

Sentry.init({
    dsn: "https://d68971176866462f8af592f7a841e98d@o1063428.ingest.sentry.io/6053825",

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production

    tracesSampleRate: 1.0,
});
