import {IMapProps} from "google-maps-react";
import {Action} from "redux";
import {FetchHousingResponse} from "./HousingTypes";


export const SET_MAP_CONFIG = 'SET_MAP_CONFIG';
export const SET_MAP_KEYWORD = 'SET_MAP_KEYWORD';
export const SET_MAP_LOCATIONS = 'SET_MAP_LOCATIONS';
export const SET_SEARCH_RESULT = 'SET_SEARCH_RESULT';


export interface SetMapConfig extends Action {
    type: typeof SET_MAP_CONFIG;
    mapConfig: MapConfig
}


export interface SetSearchResult extends Action {
    type: typeof SET_SEARCH_RESULT;
    data: FetchHousingResponse
}


export interface SetMapKeyword extends Action {
    type: typeof SET_MAP_KEYWORD;
    keyword: string
}

export interface Location {label : string, id: string}

export interface SetMapLocations extends Action {
    type: typeof SET_MAP_LOCATIONS;
    locations: Location[],
}

export interface MapConfig {
    mapProps: IMapProps | undefined,
    map: any | undefined
}

export interface MapState{
    mapConfig?:MapConfig,
    locations: Location[],
    searchResult?: FetchHousingResponse,
    keyword:string
}
