/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  AccountState,
  ApplicationAction,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  SIGNUP_FAILURE,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_RESET,
} from 'store/types';
import cookie from "js-cookie";

let user;
let token;
try {
  user = cookie.get("user") ? JSON.parse(cookie.get("user")) : {};
  token = cookie.get("token") || false;
} catch (err) {
}

const initialState: AccountState = {
  user,
  token,
  loading: {
    login_failed: false,
    login_failed_message: '',
    login_loading: false,
    signup_failed: false,
    signup_success: false,
    signup_loading: false,
    signup_failed_message: '',
    update_profile_loading: false,
    update_profile_failed: false,
    update_profile_failed_message: '',
    update_password_failed: false,
    update_password_success: false,
    update_password_loading: false,
    update_password_failed_message: '',
    update_profile_success: false
  }
};

const reducer = (state = initialState, action: ApplicationAction) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.user = undefined;
        draft.token = undefined;
        draft.loading.login_loading = true;
        draft.loading.login_failed = false;
        draft.loading.login_failed_message = '';
      });
    }
    case LOGIN_SUCCESS: {
      const { user, token } = action;
      cookie.set('token', token);
      cookie.set('user', user);
      return produce(state, (draft) => {
        draft.user = user;
        draft.token = token;
      });
    }

    case LOGIN_FAILURE: {
      const { message } = action;

      return produce(state, (draft) => {
        draft.loading.login_loading = false;
        draft.loading.login_failed = true;
        draft.loading.login_failed_message = message;
      });
    }
    //register feature
    case SIGNUP_REQUEST: {
      return produce(state, (draft) => {
        draft.loading.signup_loading = true;
        draft.loading.signup_failed = false;
        draft.loading.signup_success = false;
        draft.loading.signup_failed_message = '';
      });
    }

    case SIGNUP_SUCCESS: {
      return produce(state, (draft) => {
        draft.loading.signup_loading = false;
        draft.loading.signup_failed = false;
        draft.loading.signup_success = true;
        draft.loading.signup_failed_message = '';
      });
    }

    case SIGNUP_FAILURE: {
      const { message } = action;

      return produce(state, (draft) => {
        draft.loading.signup_loading = false;
        draft.loading.signup_failed = true;
        draft.loading.signup_success = false;
        draft.loading.signup_failed_message = message;
      });
    }

    //update password feature
    case UPDATE_PASSWORD_REQUEST: {
      return produce(state, (draft) => {
        draft.loading.update_password_loading = true;
        draft.loading.update_password_failed = false;
        draft.loading.update_password_success = false;
        draft.loading.update_password_failed_message = '';
      });
    }

    case UPDATE_PASSWORD_RESET: {
      return produce(state, (draft) => {
        draft.loading.update_password_loading = false;
        draft.loading.update_password_failed = false;
        draft.loading.update_password_success = false;
        draft.loading.update_password_failed_message = '';
      });
    }

    case UPDATE_PASSWORD_FAILURE: {
      const { message } = action;

      return produce(state, (draft) => {
        draft.loading.update_password_loading = false;
        draft.loading.update_password_failed = true;
        draft.loading.update_password_success = false;
        draft.loading.update_password_failed_message = message;
      });
    }

    case LOGOUT: {
      cookie.remove('token');
      cookie.remove('user');
      return produce(state, (draft) => {
        draft.user = undefined;
        draft.token = undefined;
      });
    }

    default: {
      return state;
    }
  }
};

export default reducer;
