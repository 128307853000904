import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

export interface ButtonPrimaryProps extends ButtonProps {
  loading?: boolean;
  disabled?: boolean;
}

export default function ButtonPrimary(props: ButtonPrimaryProps) {
  const classes = useStyles();
  const { loading = false, disabled = false } = props;
  return (
    <div className={classes.wrapper}>
      <Button disabled={props.disabled || props.loading} {...props} />
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  );
}
