// import fetch from "isomorphic-fetch";
// require("es6-promise").polyfill();
import Cookies from 'js-cookie'
// const localStorage = new SecureLS({encodingType: 'aes'});

class BaseService {
  static getHeaders = (isFile?: boolean) => {
    let headers = new Headers();
    if (!isFile) {
      headers.append("Content-Type", "application/json");
    }
    headers.append("Accept", "application/json");
    headers.append("Access-Control-Allow-Origin", " * ");
    headers.append("Credentials", "same-origin");
    headers.append("Accept-Language", `${Cookies.get('language') || "fr"};q=0.9`);
    return headers;
  };

  static getHeadersAuth = (isFile?: boolean) => {

    let headers = BaseService.getHeaders(isFile);
    /* let access_token = localStorage.get("token")
        ? localStorage.get("token").toString()
        : null; */
    let access_token = Cookies.get("token")
        ? Cookies.get("token").toString()
        : null;
    console.log("access_token", access_token);
    if (access_token === null) {
      window.location.reload();
    }
    headers.append("Authorization", `Bearer ${access_token}`);
    console.log(headers);
    return headers;
  };


  static getToken = () => {
    return Cookies.get("token").toString();
  };

  static postRequest = async (url, body, required_auth) => {
    let head = required_auth
        ? BaseService.getHeadersAuth()
        : BaseService.getHeaders();

    let headers : any = {
      method: "POST",
      headers: head,
      mode: "cors",
      cache: "default",
      body: JSON.stringify(body)
    };

    let response = await fetch(url, headers)
        .then(response => {
          return response;
        })
        .catch(err => {
          return err;
        });
    return response;
  };

  static postFileRequest = async (url, body, required_auth) => {
    let head = required_auth
        ? BaseService.getHeadersAuth(true)
        : BaseService.getHeaders(true);

    let headers : any = {
      method: "POST",
      headers: head,
      mode: "cors",
      cache: "default",
      body: body
    };
    let response = await fetch(url, headers)
        .then(response => {
          console.log(response);
          return response;
        })
        .catch(err => {
          return err;
        });
    return response;
  };

  static putFileRequest = async (url, body, required_auth) => {
    let head = required_auth
        ? BaseService.getHeadersAuth(true)
        : BaseService.getHeaders(true);
    let headers : any = {
      method: "PUT",
      headers: head,
      mode: "cors",
      cache: "default",
      body: body
    };

    console.log(headers);

    return await fetch(url, headers)
        .then(response => {
          console.log(response);
          return response;
        })
        .catch(err => {
          return err;
        });
  };

  static putRequest = async (url, body, required_auth) => {
    let head = required_auth
        ? BaseService.getHeadersAuth()
        : BaseService.getHeaders();

    let headers : any = {
      method: "PUT",
      headers: head,
      mode: "cors",
      cache: "default",
      body: JSON.stringify(body)
    };
    let response = await fetch(url, headers)
        .then(response => {
          return response;
        })
        .catch(err => {
          return err;
        });
    return response;
  };

  static deleteRequest = async (url, body, required_auth) => {
    let head = required_auth
        ? BaseService.getHeadersAuth()
        : BaseService.getHeaders();

    let headers : any = {
      method: "DELETE",
      headers: head,
      mode: "cors",
      cache: "default",
      body: JSON.stringify(body)
    };
    let response = await fetch(url, headers)
        .then(response => {
          return response;
        })
        .catch(err => {
          return err;
        });
    return response;
  };

  static getRequest = async (url, required_auth) => {
    let head = required_auth
        ? BaseService.getHeadersAuth()
        : BaseService.getHeaders();

    let headers : any = {
      method: "GET",
      headers: head,
      mode: "cors",
      cache: "default"
    };
    let response = await fetch(url, headers)
        .then(response => {
          return response;
        })
        .catch(err => {
          return err;
        });
    return response;
  };
}

export default BaseService;
