import { PaletteOptions } from '@material-ui/core/styles/createPalette';
const white = '#FFFFFF';
const black = '#000000';

const palette : PaletteOptions = {
  primary: {
    contrastText: white,
    main: '#00A8FF'
  },
  common: {
    black,
    white
  },
  secondary: {
    contrastText: white,
    main: '#111318'
  },
  error: {
    contrastText: white,
    main: '#FB2F2F'
  },
  text: {
    primary: '#222222',
    secondary: '#858585',
    hint: '#A6ADB4'
  },
  success: {
    main: '#1AB303',
    light: '#1ab30340'
  },
  background: {
    default: '#ffffff',
    paper: white
  },
  divider: "rgb(235, 235, 235)"
};

export default palette