// export const apiUrl = process.env.NEXT_PUBLIC_REACT_APP_API_URL;
export const apiUrl = "https://api.lespioles.com/api/";

export const nestApiUrl = "https://nest-api.lespioles.com/";

// export const localApiUrl = 'http://localhost:4000/api/';
export const localApiUrl = process.env.NEXT_PUBLIC_REACT_APP_SERVER + '/api/';

export const googleMapsKey = process.env.NEXT_PUBLIC_REACT_APP_MAP_API_KEY;

export const googleMapsBaseUrl = 'https://maps.googleapis.com/maps/api/place/';

export const AuthUrl = {
    LOGIN: provider => `${apiUrl}auth/login/${provider}`,
    CONNECT: provider => `${apiUrl}auth/signIn/${provider}`
};

export const GoogleUrl = {
    FETCH_HOUSING_FROM_KEYWORD: (keyword: string, filter: string, start_date?: string) => `${localApiUrl}fetch_place?keyword=${keyword}${filter ? `&filter=${filter}` : ''}${start_date ? `&start_date=${start_date}` : ''}`,
    FETCH_HOUSING_FROM_PLACE_ID: (place_id?: string, filter?: string, lat?: string, lng?: string, start_date?: string) => `${localApiUrl}fetch_housings?place_id=${place_id}${filter ? `&filter=${filter}` : ''}${lat ? `&lat=${lat}` : ''}${lng ? `&lng=${lng}` : ''}${start_date ? `&start_date=${start_date}` : ''}`,
    FETCH_PLACE: (keyword: string) => `${googleMapsBaseUrl}autocomplete/json?input=${keyword}&components=country:cm&key=${googleMapsKey}&sessiontoken=${new Date().getTime()}`,
    FETCH_GEOMETRY: (place_id: string) => `${googleMapsBaseUrl}details/json?place_id=${place_id}&fields=name,rating,geometry&key=${googleMapsKey}`,
};

export const HousingUrl = {
    FETCH_HOUSING: () => `${nestApiUrl}cities`,
    FETCH_DETAIL_HOUSING: (id: string | number) => `${nestApiUrl}cities/${id}`,
    FETCH_LIST_HOUSING_IDS: () => `${nestApiUrl}cities/next`,
    ADD_HOUSING_T_FAVORITE: () => `${apiUrl}user/favorite/toggle`,
};
