import { createMuiTheme } from '@material-ui/core';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';

const theme = createMuiTheme({
  palette,
  typography,
  shape: {
    borderRadius: 5
  },
  breakpoints: {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: {
      xs: 0,
      sm: 600,
      md: 780,
      lg: 1420,
      xl: 1920
    }
  }
});

// @ts-ignore
theme.overrides = {
  MuiCssBaseline: {
    '@global': {}
  },
  ...overrides
};

export default theme;
