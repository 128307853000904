import BaseService from './BaseService'
import {AuthUrl} from "./urls";

export default class AuthService {
    static login(provider, token) {
        return BaseService.postRequest(AuthUrl.LOGIN(provider), {token : token}, false);
    };
    static signIn(provider, payload) {
        return BaseService.postRequest(AuthUrl.CONNECT(provider), payload, false);
    };
}