import React from 'react'

export default function Facebook() {
    return <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
            <path
                d="M20.2203 21.1265C18.1391 22.8843 15.4391 23.9999 12.5 23.9999C8.12658 23.9999 4.28747 21.5906 2.20627 18.0749L2.98542 14.4833L6.43908 13.8421C7.24064 16.4296 9.65935 18.3281 12.5 18.3281C13.8781 18.3281 15.1578 17.8921 16.2125 17.1187L19.5313 17.6249L20.2203 21.1265Z"
                fill="#59C36A"/>
            <path
                d="M20.2203 21.1266L19.5313 17.6251L16.2125 17.1188C15.1578 17.8922 13.8781 18.3282 12.5 18.3282V24.0001C15.4391 24.0001 18.1391 22.8844 20.2203 21.1266Z"
                fill="#00A66C"/>
            <path
                d="M6.17187 12C6.17187 12.6468 6.27031 13.2655 6.43906 13.8422L2.20625 18.075C1.15156 16.3031 0.5 14.2218 0.5 12C0.5 9.77805 1.15156 7.69685 2.20625 5.92497L5.60342 6.50964L6.43906 10.1578C6.27031 10.7343 6.17187 11.3531 6.17187 12Z"
                fill="#FFDA2D"/>
            <path
                d="M24.5 12C24.5 15.6562 22.8079 18.9328 20.2203 21.1266L16.2125 17.1188C17.0281 16.5281 17.7172 15.7406 18.1672 14.8125H12.5C12.1062 14.8125 11.7969 14.5031 11.7969 14.1094V9.89063C11.7969 9.49683 12.1062 9.1875 12.5 9.1875H23.6C23.9375 9.1875 24.2328 9.42652 24.2891 9.76402C24.4297 10.4953 24.5 11.2546 24.5 12Z"
                fill="#4086F4"/>
            <path
                d="M18.1672 14.8125C17.7172 15.7406 17.0281 16.5281 16.2125 17.1188L20.2203 21.1266C22.8079 18.9328 24.5 15.6563 24.5 12C24.5 11.2546 24.4297 10.4953 24.289 9.76402C24.2328 9.42652 23.9375 9.1875 23.6 9.1875H12.5V14.8125H18.1672Z"
                fill="#4175DF"/>
            <path
                d="M20.4172 3.35152C20.4313 3.54839 20.3469 3.73125 20.2203 3.87183L17.211 6.86714C16.9719 7.12027 16.5782 7.14839 16.2969 6.93745C15.1859 6.10781 13.8781 5.67188 12.5 5.67188C9.65935 5.67188 7.24064 7.57027 6.43908 10.1578L2.20627 5.925C4.28747 2.40938 8.12658 0 12.5 0C15.2985 0 18.0266 1.03125 20.1641 2.8312C20.3188 2.95781 20.4031 3.15464 20.4172 3.35152Z"
                fill="#FF641A"/>
            <path
                d="M16.2969 6.93745C16.5781 7.14844 16.9718 7.12027 17.2109 6.86714L20.2203 3.87183C20.3469 3.73125 20.4313 3.54844 20.4172 3.35152C20.403 3.15459 20.3187 2.95781 20.164 2.8312C18.0266 1.03125 15.2984 0 12.5 0V5.67188C13.8781 5.67188 15.1859 6.10781 16.2969 6.93745Z"
                fill="#F03800"/>
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
            </clipPath>
        </defs>
    </svg>
}
