import * as React from 'react';
import {useSelector} from "react-redux";
import {ApplicationState} from "../store/types";
import {useState} from "react";
//import classes from './UserAuthProvider.module.scss'
//import {makeStyles} from "@material-ui/core/styles";
//import {Button} from "@material-ui/core"
//const useStyles = makeStyles(theme => ({

//}));



interface AuthLoginContextProps {
    showLoginInterface: (afterLogin: (key: string) => void, key: string) => void
}

export const AuthLoginContext = React.createContext<AuthLoginContextProps>({
    showLoginInterface: () => {},
});

interface UserAuthProviderProps {
    render: ({onUserInteract}: { onUserInteract: (e) => void }) => React.ReactNode
    handleAuthClick: () => void,
    id: string
}

export default function UserAuthProvider (props: UserAuthProviderProps) {

    const {account: {token}} = useSelector((state: ApplicationState) => state);

    return (
        <AuthLoginContext.Consumer>
            {
                ({showLoginInterface}) => {
                    return props.render({
                        onUserInteract: (e) => {
                            if(token)
                                props.handleAuthClick();
                            else{
                                showLoginInterface((key) => {
                                    if (key === props.id) {
                                        props.handleAuthClick();
                                    }
                                }, props.id);
                            }
                        }
                    });
                }
            }
        </AuthLoginContext.Consumer>
    );
};
