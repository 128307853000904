import {
  CHANGE_LANGUAGE,
  ChangeLanguage,
  InternationalisationTypes,
  SET_TRANSLATOR_FUNCTION,
  SetTranslatorFunction
} from 'store/types';
import {Translate} from "next-translate";

export const changeLanguage = (
  language: InternationalisationTypes
): ChangeLanguage => ({
  type: CHANGE_LANGUAGE,
  language
});

export const setTranslationFunction = (
  t: Translate
): SetTranslatorFunction => ({
  type: SET_TRANSLATOR_FUNCTION,
  t
});
