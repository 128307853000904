import {
    ApplicationAction,
    MapState,
    SET_MAP_CONFIG,
    SET_MAP_KEYWORD,
    SET_MAP_LOCATIONS,
    SET_SEARCH_RESULT
} from "../types";

const initialState: MapState = {
    keyword: '',
    locations: [],
}

const reducer = (state = initialState, action: ApplicationAction) => {
    switch (action.type) {
        case SET_MAP_CONFIG: {
            return {
                ...state,
                mapConfig: action.mapConfig
            };
        }
        case SET_SEARCH_RESULT: {
            return {
                ...state,
                searchResult: action.data
            };
        }
        case SET_MAP_KEYWORD: {
            return {
                ...state,
                keyword: action.keyword
            };
        }
        case SET_MAP_LOCATIONS: {
            return {
                ...state,
                locations: action.locations
            };
        }
        default: {
            return state;
        }
    }
}

export default reducer;