import produce from 'immer';
import {
  InternationalisationState,
  CHANGE_LANGUAGE,
  SET_TRANSLATOR_FUNCTION,
  ApplicationAction, InternationalisationTypes
} from 'store/types';

const DEFAULT_LANGUAGE = InternationalisationTypes.FR


export const initialState: InternationalisationState = {
  locale: DEFAULT_LANGUAGE
};

const reducer = (state = initialState, action: ApplicationAction) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return produce(state, (draft) => {
        draft.locale = action.language;
      });
    case SET_TRANSLATOR_FUNCTION:
      return produce(state, (draft) => {
        draft.t = action.t;
      });
    default:
      return state;
  }
};

export default reducer;
