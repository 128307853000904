export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
    try {
        if ((window as any).mixpanel) {
            (window as any).mixpanel.track('WebPageView', {
                page_url: url,
            });
        }
    } catch (e) {
        console.error(e);
    }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = (name: string, props: any) => {
    try {
        if ((window as any).mixpanel) {
            (window as any).mixpanel.track(name, props);
        }
    } catch (e) {
        console.log(e);
    }
}


export const  identify = (id: number, name: string, surname: string) => {
    try {
        if ((window as any).mixpanel) {
            (window as any).mixpanel.identify(`${id}`);
            (window as any).people.set({"$first_name,": surname})
            (window as any).people.set({"$last_name,": name})
        }
    } catch (e) {
        console.log(e)
    }
}
