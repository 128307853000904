

export default {
  env: process.env.NEXT_PUBLIC_ENV,
  // stripe_pub_key: process.env[`REACT_APP_${host_var}_STRIPE_PUB_KEY`] || '',
  // paypal_client_id: process.env[`REACT_APP_${host_var}_PAYPAL_CLIENT_ID`] || '',
  api_url: process.env.NEXT_PUBLIC_REACT_APP_API_URL,
  // socket_url: process.env[`REACT_APP_${host_var}_SOCKETS_URL`] || '',
  map_key: process.env.NEXT_PUBLIC_REACT_APP_MAP_API_KEY
};
