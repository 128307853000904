import palette from '../palette';

export default {
  root: {
    background: '#FFFF',
    minHeight: 45,
    '& fieldset': {
      borderRadius: 33
    }
  },
  input: {
    '&::placeholder': {
      opacity: 1,
      color: palette.text.hint
    }
  }
};
