import {IMapProps, Map} from "google-maps-react";
import BaseService from "./BaseService";
import {GoogleUrl} from "./urls";

export interface MapConfig {
    mapProps: IMapProps | undefined,
    map: any | undefined
}

class GoogleService {

    static fetch_place = async (keyword: string, mapConfig: MapConfig | undefined) => {
        return new Promise((resolve, reject) => {
            if (mapConfig && mapConfig.mapProps) {
                const {google} = mapConfig.mapProps;
                const service = new google.maps.places.AutocompleteService();
                const sessionToken = new google.maps.places.AutocompleteSessionToken();
                let request = {
                    input: keyword,
                    sessionToken,
                    componentRestrictions: {country: 'cm'}
                };
                service.getPlacePredictions(request, function (results: any, status: any) {
                    if (status === google.maps.places.PlacesServiceStatus.OK) {
                        return resolve({
                            status: 200,
                            json: () => new Promise((resolve, reject) => {
                                resolve(results)
                            })
                        });
                    } else {
                        return reject({
                            status: 502,
                            json: () => new Promise((resolve, reject) => {
                                resolve([])
                            })
                        });
                    }
                });
            } else {
                return reject({
                    status: 504,
                    json: () => new Promise((resolve, reject) => {
                        resolve([])
                    })
                });
            }
        });
    };

    static fetch_server_place = async (keyword: string) => {
        return BaseService.getRequest(GoogleUrl.FETCH_PLACE(keyword), false);
    }

    static fetch_server_location = async (place_id: string) => {
        return BaseService.getRequest(GoogleUrl.FETCH_GEOMETRY(place_id), false);
    }

    static fetch_housings_from_keyword = async (keyword: string, filter: string) => {
        return BaseService.getRequest(GoogleUrl.FETCH_HOUSING_FROM_KEYWORD(keyword, filter), false);
    }

    static fetch_housings_from_place_id = async (place_id?: string, filter?: string, lat?: string, lng?: string) => {

        console.log('Get payload lat', lat);
        console.log('Get payload lng', lng);

        return BaseService.getRequest(GoogleUrl.FETCH_HOUSING_FROM_PLACE_ID(place_id, filter, lat, lng), false);
    }

}

export default GoogleService
